import { useEffect } from 'react';

const ActivityTracker = ({ ws }) => {
    useEffect(() => {
        const handleMouseMove = (event) => {
            ws.send({ command: "mouse", params: { x: event.clientX, y: event.clientY } });
        };

        const handleKeyDown = (event) => {
            ws.send({ command: "keyboard", params: { key: event.key } });
        };

        const handleMouseClick = (event) => {
            const button = event.button === 0 ? 'Left Click' : event.button === 1 ? 'Middle Click' : 'Right Click';
            ws.send({ command: "mouse_click", params: { button: button, x: event.clientX, y: event.clientY } });
        };

        const handleScroll = (event) => {
            ws.send({ command: "mouse_scroll", params: { delta: event.deltaY } });
        };

        const handleVisibilityChange = () => {
            const isVisible = !document.hidden;
            ws.send({ command: "visibility", params: { visible: isVisible } });
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('click', handleMouseClick);
        window.addEventListener('wheel', handleScroll);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('click', handleMouseClick);
            window.removeEventListener('wheel', handleScroll);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [ws]);

    return null; // This component does not render anything
};

export default ActivityTracker;
