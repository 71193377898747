import "../css/development.css";
import settings from '../assets/settings.png';
import NoRightClickImage from './Disable';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function About() {
    return (
        <HelmetProvider>
            <Helmet title="About | Vista Ai">
                <meta name="description" content="About page of Vista Ai" />
            </Helmet>
            <center>
                <NoRightClickImage src={settings} className="logo" alt="Vite logo" />
                <div>Under Development</div>
            </center>
        </HelmetProvider>
    );
}


export default About;
