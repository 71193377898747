import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/nav.css";
import menu from "../assets/menu.png";
import logo from "../assets/icon.png";
import NoRightClickImage from "../pages/Disable";


const Navigation = ({ ws }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [is_login, setlogin] = useState(false);

  useEffect(() => {
    const handleWebSocketMessage = (message) => {
      if (message.command === 'checkuserid') {
        if (!message.is_valid) {
          localStorage.removeItem("user_id");
        }
      }
    };

    ws.on("message", handleWebSocketMessage);

    const interval = setInterval(() => {
      const user_id = localStorage.getItem("user_id");
      if (!user_id) {
        setlogin(false);
      } else {
        setlogin(true);
        ws.send({ command: "checkuserid", params: { user_id } });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      ws.off("message", handleWebSocketMessage);
    };
  }, [ws]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <nav>
      <div className="icon">
        <Link to="/">
          <NoRightClickImage src={logo} className="icon" alt="Vista Logo" />
        </Link>
      </div>
      <ul className="link">
        <li>
        <Link to={is_login ? "/chat" : "/pricing"}>
        {is_login ? "Chat" : "Pricing"}</Link>
        </li>
        <li>
          <Link to="/tools">Tools</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to={is_login ? "/dashboard" : "/start"} className="button">
            {is_login ? "Dashboard" : "Get Started"}
          </Link>
        </li>
      </ul>
      <div onClick={toggleDropdown}>
        <img className="in" src={menu} alt="menu bar" />
      </div>
      <div className={`dropdown ${dropdownOpen ? "open" : ""}`}>
        <li>
          <Link to={is_login ? "/chat" : "/pricing"}>
          {is_login ? "Chat" : "Pricing"}</Link>
        </li>
        <li>
          <Link to="/tools">Tools</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to={is_login ? "/dashboard" : "/start"} className="button">
            {is_login ? "Dashboard" : "Get Started"}
          </Link>
        </li>
      </div>
    </nav>
  );
};

export default Navigation;