import "../css/development.css";
import settings from '../assets/settings.png';
import NoRightClickImage from './Disable';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function Pop() {
    return (
        <HelmetProvider>
            <Helmet title="Privacy Policy | Vista Ai">
                <meta name="description" content="Privacy Policy page of Vista AI" />
            </Helmet>
            <center>
                <center>
                    <NoRightClickImage src={settings} className="logo" alt="Vite logo" />
                    <div>Under Development</div>
                </center>
            </center>
        </HelmetProvider>
    );
}


export default Pop;
