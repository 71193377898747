import React from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "../css/404.css";

const PageNotFound = () => (
  <HelmetProvider>
    <div className="center">
      <Helmet title="404 Not Found | Vista Ai">
        <meta
          name="description"
          content="The content you are looking for cannot be found."
        />
        <meta
          name="Keyword"
          content="404,notfound,not-found,vista,digitalmarketing,marketing,ai,ai-agency,tech,digital,advanced-ai"
        />
      </Helmet>

      <center>
        <h1>Page Not Found</h1>
        <p>
          Return <Link to="/">home</Link>.
        </p>
      </center>
    </div>
  </HelmetProvider>
);

export default PageNotFound;
