import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import '../css/imagegen.css';

const models = ["Model1", "Model2", "Model3"]; // Replace with actual model names

function ImageGenerationPage({ws}) {
    const [prompt, setPrompt] = useState('');
    const [negativePrompt, setNegativePrompt] = useState('');
    const [seed, setSeed] = useState(10000000);
    const [model, setModel] = useState(models[0]);
    const [generationHistory, setGenerationHistory] = useState([]);
    const [status, setStatus] = useState('');
    const [generatedImage, setGeneratedImage] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const handleWebSocketMessage = (message) => {
            const data = message.data;
            if (message.command === 'generate') {
                if (data.status === 'success') {
                    setGeneratedImage(data.imageUrl);
                    setGenerationHistory(prev => [...prev, { prompt, negativePrompt, model, imageUrl: data.imageUrl }]);
                    setStatus('Generation successful');
                } else if (data.status === 'failed') {
                    setStatus('Generation failed');
                }
            }
        };
        ws.on('message', handleWebSocketMessage);
        return () => {
            ws.off('message', handleWebSocketMessage);
          };
        }, [ws, prompt, negativePrompt, model]);

        const handleGenerate = () => {
            const newErrors = {};

        if (!prompt.trim()) newErrors.prompt = 'Prompt is required';
        if (seed === -1) newErrors.seed = 'Seed cannot be -1';
        if (!model) newErrors.model = 'Model is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setErrors({});
            setStatus('Generating...');
            ws.send({
                    command: 'generate',
                    prompt,
                    negativePrompt,
                    seed: seed === -1 ? -1 : seed,
                    model
                });
            };

    const handleSeedChange = (e) => {
        setSeed(e.target.value);
    };

    return (
        <HelmetProvider>
            <Helmet title="Image Generation | Vista AI">
                <meta name="description" content="Generate images with advanced AI models." />
            </Helmet>
            <div className="image-gen-container">
                <h1>Image Generation</h1>
                <div className="form-container">
                    <label>
                        Prompt:
                        <textarea value={prompt} onChange={(e) => setPrompt(e.target.value)} required/>
                        {errors.prompt && <p className="error">{errors.prompt}</p>}
                    </label>
                    <label>
                        Negative Prompt:
                        <textarea value={negativePrompt} onChange={(e) => setNegativePrompt(e.target.value)} />
                    </label>
                    <label>
                        Seed:
                        <input
                            type="range"
                            min="10000000"
                            max="99999999999"
                            value={seed}
                            onChange={handleSeedChange}
                            step="1"
                        />
                        <input
                            type="number"
                            value={seed === -1 ? '' : seed}
                            onChange={(e) => setSeed(e.target.value === '' ? -1 : Number(e.target.value))}
                        />
                    </label>
                    <label>
                        Model:
                        <select value={model} onChange={(e) => setModel(e.target.value)}>
                            {models.map((modelName) => (
                                <option key={modelName} value={modelName}>{modelName}</option>
                            ))}
                        </select>
                    </label>
                    <button onClick={handleGenerate}>Generate Image</button>
                </div>
                {status && <div className="status">{status}</div>}
                {generatedImage && <div className="image-container"><img src={generatedImage} alt="Generated" /></div>}
                <div className="history">
                    <h2>Generation History</h2>
                    {generationHistory.length > 0 ? (
                        <ul>
                            {generationHistory.map((entry, index) => (
                                <li key={index}>
                                    <p><strong>Prompt:</strong> {entry.prompt}</p>
                                    <p><strong>Negative Prompt:</strong> {entry.negativePrompt}</p>
                                    <p><strong>Model:</strong> {entry.model}</p>
                                    <img src={entry.imageUrl} alt={`History ${index}`} />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No history available</p>
                    )}
                </div>
            </div>
        </HelmetProvider>
    );
}

export default ImageGenerationPage;